import React from "react";
import ReactDOM from "react-dom";

import "./assets/style/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/base.css";
import "./assets/style/responsive.css";

import "./assets/style/left.css";
import "./assets/style/night-mode.css";
import "./assets/style/zeynep.css";
import "./assets/style/bootstrap-tagsinput.css";
import "./assets/style/bootstrap-tagsinput.less";
import "./assets/style/semantic.min.css";
import "./assets/style/step-wizard.css";
import { Provider } from "react-redux";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import configureStore from "./redux/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
