import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { isAuthenticated, isGuest } from "./auth";
import ErrorBoundary from "./helpers/ErrorBoundary";

const Home = React.lazy(() => import("./containers/Home"));
const SearchContainer = React.lazy(() =>
  import("./containers/SearchContainer")
);

const ProductDetailsContainer = React.lazy(() =>
  import("./containers/ProductDetailsContainer")
);
const MyAccountContainer = React.lazy(() =>
  import("./containers/MyAccountContainer")
);
const PageNotFoundContainer = React.lazy(() =>
  import("./containers/PageNotFoundContainer")
);
const OrdersContainer = React.lazy(() =>
  import("./containers/OrdersContainer")
);
const WishlistContainer = React.lazy(() =>
  import("./containers/WishlistContainer")
);
const OrdersSummeryContainer = React.lazy(() =>
  import("./containers/OrdersSummeryContainer")
);
const CheckoutContainer = React.lazy(() =>
  import("./containers/CheckoutContainer")
);
const BrandProductsContainer = React.lazy(() =>
  import("./containers/BrandProductsContainer")
);
const OrderPlacedContainer = React.lazy(() =>
  import("./containers/OrderPlacedContainer")
);
const FaqContainer = React.lazy(() => import("./containers/FaqContainer"));
const PrivacyPolicyContainer = React.lazy(() =>
  import("./containers/PrivacyPolicyContainer")
);
const ReturnPolicyContainer = React.lazy(() =>
  import("./containers/ReturnPolicyContainer")
);
const TermConditionsContainer = React.lazy(() =>
  import("./containers/TermConditionsContainer")
);
const CancellationPolicyContainer = React.lazy(() =>
  import("./containers/CancellationPolicyContainer")
);
const CompanyContainer = React.lazy(() =>
  import("./containers/CompanyContainer")
);
const OrderReviewContainer = React.lazy(() =>
  import("./containers/OrderReviewContainer")
);
const ReturnContainer = React.lazy(() =>
  import("./containers/ReturnContainer")
);
const ReturnItemsContainer = React.lazy(() =>
  import("./containers/ReturnItemsContainer")
);
const OfferListContainer = React.lazy(() =>
  import("./containers/OfferListContainer")
);
const SuperSaverContainer = React.lazy(() =>
  import("./containers/SuperSaverContainer")
);
const AddressContainer = React.lazy(() =>
  import("./containers/AddressContainer")
);
const AddAddressContainer = React.lazy(() =>
  import("./containers/AddAddressContainer")
);
const EditAddressContainer = React.lazy(() =>
  import("./containers/EditAddressContainer")
);
const ContactUsContainer = React.lazy(() =>
  import("./containers/ContactUsContainer")
);
const CategoryContainer = React.lazy(() =>
  import("./containers/CategoryContainer")
);
const Login = React.lazy(() => import("./containers/Login"));
const Logout = React.lazy(() => import("./containers/Logout"));
const ForgotPassword = React.lazy(() => import("./containers/ForgotPassword"));
const Register = React.lazy(() => import("./containers/Register"));
const VerifyOTP = React.lazy(() => import("./containers/VerifyOTP"));
const ResetPasswordVerifyOTP = React.lazy(() =>
  import("./containers/ResetPasswordVerifyOTP")
);
const SiteLayout = React.lazy(() => import("./layouts/SiteLayout"));
const CheckoutLayout = React.lazy(() => import("./layouts/CheckoutLayout"));
const PlainLayout = React.lazy(() => import("./layouts/PlainLayout"));

const routeList = [ 
  {
    path: "/",
    component: Home,
    layout: SiteLayout,
    exact: true,
    private: false,
  },
  {
    path: "/search",
    component: SearchContainer,
    layout: SiteLayout,
    exact: true,
    private: false,
  },
  {
    path: "/category/:can_name",
    component: CategoryContainer,
    layout: SiteLayout,
    exact: true,
    private: false,
  },
  {
    path: "/product/:id",
    component: ProductDetailsContainer,
    layout: SiteLayout,
    exact: true,
    private: false,
  },
  {
    path: "/product/:id/:can_name",
    component: ProductDetailsContainer,
    layout: SiteLayout,
    exact: true,
    private: false,
  },
  {
    path: "/login",
    component: Login,
    layout: PlainLayout,
    private: false,
  },
  {
    path: "/register",
    component: Register,
    layout: PlainLayout,
    private: false,
  },
  {
    path: "/logout",
    component: Logout,
    layout: PlainLayout,
    private: false,
  },
  {
    path: "/otp-verification/:token",
    component: VerifyOTP,
    layout: PlainLayout,
    private: false,
  },
  {
    path: "/checkout",
    component: CheckoutContainer,
    // layout: SiteLayout,
    layout: CheckoutLayout,
    private: false,
  },
  {
    path: "/brand/:id",
    component: BrandProductsContainer,
    layout: SiteLayout,
    private: false,
  },
  {
    path: "/order-placed",
    component: OrderPlacedContainer,
    layout: SiteLayout,
    private: false,
  },

  {
    path: "/profile",
    component: MyAccountContainer,
    layout: SiteLayout,
    private: true,
  },
  {
    path: "/orders",
    component: OrdersContainer,
    layout: SiteLayout,
    private: true,
  },
  {
    path: "/order-summery/:id",
    component: OrdersSummeryContainer,
    layout: SiteLayout,
    private: true,
  },
  {
    path: "/reviews/:id",
    component: OrderReviewContainer,
    layout: SiteLayout,
    private: true,
  },
  {
    path: "/wishlists",
    component: WishlistContainer,
    layout: SiteLayout,
    private: true,
  },
  {
    path: "/returns",
    component: ReturnContainer,
    layout: SiteLayout,
    private: true,
  },
  {
    path: "/return-items/:id",
    component: ReturnItemsContainer,
    layout: SiteLayout,
    private: true,
  },
  {
    path: "/faq",
    component: FaqContainer,
    layout: SiteLayout,
    private: false,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicyContainer,
    layout: SiteLayout,
    private: false,
  },
  {
    path: "/return-policy",
    component: ReturnPolicyContainer,
    layout: SiteLayout,
    private: false,
  },
  {
    path: "/terms-conditions",
    component: TermConditionsContainer,
    layout: SiteLayout,
    private: false,
  },
  {
    path: "/cancellation-policy",
    component: CancellationPolicyContainer,
    layout: SiteLayout,
    private: false,
  },
  {
    path: "/company",
    component: CompanyContainer,
    layout: SiteLayout,
    private: false,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    layout: PlainLayout,
    private: false,
  },
  {
    path: "/address-book",
    component: AddressContainer,
    layout: SiteLayout,
    private: true,
  },
  {
    path: "/add-Address",
    component: AddAddressContainer,
    layout: SiteLayout,
    private: true,
  },
  {
    path: "/edit-address/:id",
    component: EditAddressContainer,
    layout: SiteLayout,
    private: true,
  },
  {
    path: "/reset-password/:token",
    component: ResetPasswordVerifyOTP,
    layout: PlainLayout,
    private: false,
  },
  {
    path: "/offer-listing/:itemId",
    component: OfferListContainer,
    layout: SiteLayout,
    private: false,
  },
  {
    path: "/super-saver/:itemId",
    component: SuperSaverContainer,
    layout: SiteLayout,
    private: false,
  },
  {
    path: "/contact-us",
    component: ContactUsContainer,
    layout: SiteLayout,
    private: false,
  },
  {
    path: "",
    component: PageNotFoundContainer,
    layout: SiteLayout,
    private: false,
  },
];

const RouteWithSubRoutes = (route, key) => {
  return (
    <Route
      key={key}
      path={route.path}
      exact={route.exact}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.layout>
          <route.component {...props} />
        </route.layout>
      )}
    />
  );
};
const RouteWithPrivateRoutes = (route, key) => {
  if (isAuthenticated() && !isGuest()) {
    return (
      <Route
        key={key}
        path={route.path}
        exact={route.exact}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <route.layout>
            <route.component {...props} />
          </route.layout>
        )}
      />
    );
  }

  return <Redirect to='/' />;
};

export const Routes = () => {
  const loader = (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className='spinner-border' />
    </div>
  );

  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={loader}>
          <Switch>
            {routeList.map((route, i) => {
              if (route.private) {
                return <RouteWithPrivateRoutes key={1} {...route} />;
              }
              return <RouteWithSubRoutes key={1} {...route} />;
            })}
            <Route component={() => (<div>404 Not found </div>)} />

          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};
