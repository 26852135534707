import {
  LOAD_MORE_ACTION_SUCCESS,
  MAIN_SEARCH_ACTION_CLEAR,
  MAIN_SEARCH_ACTION_FAILURE,
  MAIN_SEARCH_ACTION_START,
  MAIN_SEARCH_ACTION_SUCCESS,
} from "../constants";

const searchReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case MAIN_SEARCH_ACTION_START:
      return {
        loader: true,
        result: state.result,
        products: state.products,
        error: false,
      };
    case MAIN_SEARCH_ACTION_SUCCESS:
      return {
        loader: false,
        result: payload.data && payload.data.length > 0 ? payload.data[0] : {},
        products:
          payload.data && payload.data.length > 0
            ? payload.data[0].products
            : [],
        error: false,
      };
    case LOAD_MORE_ACTION_SUCCESS:
      return {
        loader: false,
        result: payload.data && payload.data.length > 0 ? payload.data[0] : {},
        products:
          payload.data && payload.data.length > 0
            ? [...state.products, ...payload.data[0].products]
            : state.products,
        error: false,
      };
    case MAIN_SEARCH_ACTION_CLEAR:
      return {};
    case MAIN_SEARCH_ACTION_FAILURE:
      return {
        loader: false,
        result: {},
        products: [],
        error: true,
      };

    default:
      return state;
  }
};
export default searchReducer;
