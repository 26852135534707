import { privateInstance, publicInstance } from "../axios";
import { AUTH_SESSION_NAME, LOCAL_SESSION_TOKEN_NAME } from "../helpers/config";

export const isAuthenticated = () => {
  const userSession = getUserSession();
  if (userSession && userSession.authToken) {
    return true;
  }
  return false;
};
export const isGuest = () => {
  const userSession = getUserSession();
  if (userSession && userSession.userType === 2) {
    return true;
  }
  return false;
};
export const haveAccess = (group) => {
  let userSession = localStorage.getItem(AUTH_SESSION_NAME);

  if (userSession) {
    userSession = JSON.parse(userSession);
    if (group.includes(userSession.role)) {
      return true;
    } else return false;
  }

  return false;
};
export const getUserSession = () => {
  const userSession = localStorage.getItem(AUTH_SESSION_NAME);
  if (userSession) return JSON.parse(userSession);
  return {};
};
export const setUserSession = (data) => {
  localStorage.setItem(AUTH_SESSION_NAME, JSON.stringify(data));

  return {};
};
export const clearUserSession = () => {
  localStorage.removeItem(AUTH_SESSION_NAME);
};

export const setLocalSession = () => {
  let oldSession = getLocalSession();
  if (oldSession) {
    return false;
  }
  let length = "30";
  let randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  localStorage.setItem(LOCAL_SESSION_TOKEN_NAME, result);
};
export const refreshLocalSession = () => {
  let length = "30";
  let randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  localStorage.setItem(LOCAL_SESSION_TOKEN_NAME, result);
};

export const getLocalSession = () => {
  return localStorage.getItem(LOCAL_SESSION_TOKEN_NAME);
};

export const loginAction = async (formData) => {
  return await publicInstance
    .post("login", formData)
    .then((res) => {
      if (res.data.status === 200) {
        const {
          authToken,
          email,
          expiry_time,
          user_id,
          time_zone,
          refreshToken,
          full_name,
          expiry_time_format,
        } = res.data.data;
        localStorage.setItem(
          AUTH_SESSION_NAME,
          JSON.stringify({
            authToken,
            email,
            expiry_time,
            user_id,
            time_zone,
            refreshToken,
            full_name,
            expiry_time_format,
          })
        );
        return res.data.status;
      } else {
        return res.data.message;
      }
    })
    .catch((error) => {
      return "API error";
    });
};
export const registerAction = async (formData) => {
  // return {
  //   authToken: "-D5TUjLIAJCczpiy5P_OzrWKuLlfozNU",
  //   email: "arun5d2d@yopmail.com",
  //   expiryTime: 1634743158,
  //   expiryTimeFormat: "2021-10-20 18:19:18",
  //   fullName: "arun4",
  //   refreshToken: "069bf566bf6b0a8aff9e118d9fa056cfaa9e930913a2cb0da3",
  //   status: 10,
  //   timeZone: "Asia/Qatar",
  //   userId: 11,
  // };
  return await publicInstance
    .post("register", formData)
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else {
        return res.data.message;
      }
    })
    .catch((error) => {
      return "API error";
    });
};
export const verifyOtpAction = async (formData) => {
  const submitData = {
    otp: `${formData.otp1}${formData.otp2}${formData.otp3}${formData.otp4}${formData.otp5}`,
    userId: formData.userId,
  };

  return await publicInstance
    .post("verify-otp", submitData)
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.status;
      } else {
        return res.data.message;
      }
    })
    .catch((error) => {
      return "API error";
    });
};

export const logoutAction = async (history) => {
  return publicInstance
    .get("/logout")
    .then((res) => {
      if (res.status === 200) {
        localStorage.clear();
        return 1;
      } else {
        return 0;
      }
    })
    .catch((error) => {
      return "0";
    });

  // history.push("/");
};

export const forgotPasswordAction = async (formData) => {
  return await publicInstance
    .post("forgot-password", formData)
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.data;
      } else {
        return res.data.message;
      }
    })
    .catch((error) => {
      return "API error";
    });
};

export const resetPasswordAction = async (formData) => {
  const submitData = {
    otp: `${formData.otp1}${formData.otp2}${formData.otp3}${formData.otp4}${formData.otp5}`,
    userId: formData.userId,
    password: formData.password,
    username: formData.username,
  };

  return await publicInstance
    .post("set-new-password", submitData)
    .then((res) => {
      if (res.data.status === 200) {
        return res.data.status;
      } else {
        return res.data.message;
      }
    })
    .catch((error) => {
      return "API error";
    });
};
